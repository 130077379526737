<template>
  <v-dialog
    v-model="dialog"
    max-width="300"
    :persistent="load"
  >
    <v-card :disabled="load">
      <v-card-title>
        Modificar
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-3 pb-0 text-center body-1">
        Límite de crédito del cliente <strong>{{ nombre }}</strong>
        <v-text-field-money
          v-model.trim="limite"
          class="mx-8 pt-3"
          v-bind:properties="{
            prefix: '$',
            type: 'tel',
            autofocus: true,
            outlined: true,
            dense: true
          }"
          v-bind:options="{
            locale: 'es-AR',
            precision: 0,
            empty: 0
          }"
        />
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="mr-2"
          color="error"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :disabled="!codigo"
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Modal para modificar el limite de credito de un gestor
 * 
 *  Notas: este componente emite la accion (@actualizar) al padre caundo se modifica el limite, enviando codigo y nuevo limite
 */
import BtnConfirmar from '../../util/BtnConfirmar'

export default {
  data () {
    return {
      load: false,
      limite: null,
      codigo: null,
      nombre: ''
    }
  },
  props: {
    value: Boolean,
    p_gestor: Object
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    async dialog (val) {
      if (val) {
        this.limite = null
        this.codigo = null
        this.nombre = ''
        if (this.p_gestor) {
          this.limite = this.p_gestor.limite_credito
          this.codigo = this.p_gestor.codigo
          this.nombre = this.p_gestor.nombre
        }
      }
    }
  },
  methods: {
    async guardar () {
      const limite = parseFloat(this.limite)
      if (limite < 0) {
        return this.$store.dispatch('show_snackbar', {
          text: 'El límite de crédito no puede ser menor que 0',
          color: 'orange'
        })
      }
      this.load = true
      await this.$store.dispatch('gestores/editar_limcred', {
        codigo: this.codigo,
        limite: limite
      })
        .then((res) => {
          this.dialog = false
          // emite la accion definifa en el padre
          this.$emit('actualizar', this.codigo, limite)
          // muestra mensaje
          this.$swal.fire({
            title: res.message,
            icon: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    }
  }
}
</script>