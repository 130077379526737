<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
       <v-row>
        <v-col cols="12" sm="6">
          <v-card class="pa-3">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="4" sm="3" class="py-1">
                  Código
                  <v-text-field
                    v-model.trim="gestor"
                    type="number"
                    :readonly="bloquear"
                    :filled="bloquear"
                    hide-details
                    outlined
                    dense
                    @change="getCliente()"
                  >
                    <template v-if="!bloquear" v-slot:append-outer>
                      <v-icon
                        class="mt-1"
                        color="info"
                        title="Buscar cliente"
                        small
                        @click="dialog_clientes.activo = true"
                      >
                        fas fa-search
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="8" sm="9" class="py-1">
                  Cliente
                  <v-text-field
                    v-model.trim="nombre"
                    hide-details
                    readonly
                    outlined
                    filled
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <BtnFiltro
                  v-if="!bloquear"
                  :loading="load"
                  clase="mt-0"
                  @clear="limpiar(1)"
                />
                <v-col v-else class="d-flex justify-end align-end">
                  <v-btn
                    color="info"
                    type="submit"
                    :loading="load"
                  >
                    <v-icon left>fas fa-sync</v-icon>
                    Refrescar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
        <v-col v-if="data.length > 0" cols="12" sm="6">
          <v-card class="pa-3">
            <v-row class="d-flex align-center">
              <v-col cols="12" sm="6" style="font-size: 20px">
                Saldo:
                <strong :class="`${saldo < 0 ? 'error' : 'success'}--text`">
                  {{ formatMoney(saldo) }}
                </strong>
              </v-col>
              <v-col v-if="limite > 0" cols="12" sm="6">
                Limite Crédito:
                <strong style="font-size: 18px;">
                  {{ formatMoney(limite) }}
                </strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="favor > 0" cols="12" sm="6">
                A Favor:
                <strong style="font-size: 18px;">
                  {{ formatMoney(favor) }}
                </strong>
              </v-col>
              <v-col v-if="saldo != disp_vta && disp_vta > 0" cols="12" sm="6">
                Disp. para Venta:
                <strong style="font-size: 18px;">
                  {{ formatMoney(disp_vta) }}
                </strong>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-data-table
        v-on:item-expanded="getFormasPago"
        class="cebra elevation-2 mt-2"
        sort-by="id"
        :items="data"
        :headers="headers"
        :expanded.sync="expanded"
        :item-class="itemRowBackground"
        :page.sync="page"
        :loading="load"
        :footer-props="{
          'items-per-page-options': [ 30, 50, 100 ],
          'show-first-last-page':true,
          'show-current-page':true
        }"
        single-expand
        show-expand
        sort-desc
        dense
      >
        <!-- tabla de formas de pago -->
        <template v-slot:expanded-item="{ headers }">
          <td :colspan="headers.length">
            <div class="d-flex justify-start">
            <v-data-table
              order-by="forma_cobro_nombre"
              class="cebra ml-10 ma-1"
              :style="$vuetify.breakpoint.xs ? 'width: 100%;' : 'width:40%;'"
              :items-per-page="-1"
              :items="formas_pago"
              :headers="[
                { text: 'Forma de pago', value: 'forma_pago' },
                { text: 'Importe', value: 'importe', align: 'right' },
                { text: '', value: 'actions', sortable: false, filterable: false }
              ]"
              hide-default-footer
              dense
            >
              <template v-slot:[`item.importe`]="{ item }">
                {{ formatMoney(item.importe) }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  v-if="item.detalle_numero != null"
                  color="info"
                  title="Ver acreditación"
                  small
                  @click="verAcreditacion(item)"
                >
                  fas fa-eye
                </v-icon>
              </template>
            </v-data-table>
          </div>
          </td>
        </template>
        <!-- columnas formateadas -->
        <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
          <v-btn
            v-if="item.detalle == 1 && item.tipo == tipo_cob"
            title="Detalle"
            small
            icon
            @click="isExpanded ? expanded = [] : expand($event)"      
          >
            <v-icon>
              fas fa-chevron-{{ isExpanded ? 'up' : 'down' }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.fecha`]="{ value }">
          {{ moment(value).format('DD/MM/YYYY') }}
        </template>
        <template v-slot:[`item.importe`]="{ value }">
          {{ formatMoney(-1 * value) }}
        </template>
        <template v-slot:[`item.saldo`]="{ value }">
          {{ formatMoney(value) }}
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="permiso == 1 && item.detalle == 1"
            color="info"
            title="Ver"
            small
            @click="verDetalle(item)"
          >
            fas fa-eye
          </v-icon>
          <v-icon
            v-if="item.detalle == 1 && item.tipo == tipo_vta"
            title="Remito"
            color="indigo"
            class="ml-2"
            small
            @click="getRemito(item)"
          >
            fas fa-receipt
          </v-icon>
          <v-icon
            v-if="item.detalle == 1 && item.tipo == tipo_cob"
            title="Recibo"
            color="purple"
            class="ml-2"
            small
            @click="getRecibo(item)"
          >
            fas fa-receipt
          </v-icon>
        </template>
      </v-data-table>
    </v-col>
    <!-- COMPONENTES -->
    <BuscarClienteMayorista
      :datos="dialog_clientes"
      @setearCliente="setCliente"
      @setearBuscarClienteM="setDialogClienteMay"
    />
    <PDFViewer
      v-model="pdf_dialog"
      :pdf="pdf"
      :nombre="pdf_nombre"
      :titulo="pdf_nombre"
    />
  </v-row>
</template>

<script>
import moment from 'moment'
import { format_money } from '@/util/utils'
import { remitoReciboA4, reciboLitechA4 } from '@/util/plantillas/pdfs'
import BtnFiltro from '@/components/util/BtnFiltro.vue'
import BuscarClienteMayorista from '@/components/generales/clientes/BuscarClienteMayorista.vue'
import PDFViewer from '@/util/plantillas/PDFViewer.vue'

export default {
  data () {
    return {
      moment: moment,
      formatMoney: format_money,
      numero: undefined,
      tipo_cob: 'RECCOB',
      tipo_vta: 'VTA-MAYORISTA',
      page: 1,
      permiso: 0,
      panel: 0,
      saldo: 0,
      favor: 0,
      limite: 0,
      disp_vta: 0,
      load: false,
      bloquear: false,
      gestor: null,
      nombre: null,
      pdf_dialog: false,
      pdf_nombre: '',
      pdf: null,
      data: [],
      expanded: [],
      formas_pago: [],
      headers: [
        { text: 'Fecha', value: 'fecha', sortable: false, filterable: false },
        { text: 'Tipo', value: 'tipo_det', sortable: false, filterable: false },
        { text: 'Número', value: 'numero', sortable: false, filterable: false },
        { text: 'Referencia', value: 'descripcion', sortable: false, filterable: false },
        { text: 'Importe', value: 'importe', align: 'right', sortable: false, filterable: false },
        { text: 'Saldo', value: 'saldo', align: 'right', sortable: false, filterable: false },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false }
      ],
      dialog_clientes: {
        ruta: 'clientes/getClienteMayorista',
        activo: false,
        ejecutivo_codigo: 0
      }
    }
  },
  async created () {
    this.numero = this.$route.query.numero

    this.$store.state.loading = true
    let result = await this.$store.dispatch('acreditaciones/initForm')
    this.$store.state.loading = false

    if (result.resultado != 1) {
      this.bloquear = true
      this.$store.dispatch('show_snackbar', {
        text: result.msj,
        color: 'error'
      })
      return
    }

    // si es ejecutivo lo seteo
    if (result.ejecutivo.length == 1) {
      this.dialog_clientes.ejecutivo_codigo = result.ejecutivo[0].ejecutivo_codigo
    }

    // tiene permiso de ver todos los clientes?
    if (result.permiso == 1) {
      this.permiso = result.permiso
      const gestor = this.$route.query.gestor
      if (gestor) {
        this.gestor = gestor
        this.getCliente()
      }
      return
    }

    // si no tiene permiso, asigno el resto de la info, vino con datos de cliente?
    if (result.cliente.length > 0) {
      // vino con datos de cliente por lo tanto lleno el codigo de cliente y no permito cambiar
      this.bloquear = true
      this.gestor = result.cliente[0].cliente_codigo
      this.nombre = result.cliente[0].cliente_nombre
      this.buscar()
    }

  },
  components: {
    BtnFiltro,
    BuscarClienteMayorista,
    PDFViewer
  },
  watch: {
    gestor (val) {
      this.limpiar()
      if (!val) {
        this.nombre = null
      }
    }
  },
  methods: {
    async verAcreditacion (item) {
      if (this.expanded.length != 1) return

      let routeData = this.$router.resolve({
        path: '/acreditaciones',
        query: {
          gestor: this.expanded[0].vendedor_codigo,
          numero: item.detalle_numero
        }
      })
      window.open(routeData.href, '_blank')
    },
    async getFormasPago (payload) {
      this.formas_pago = []

      if (!payload.value) return

      this.$store.state.loading = true
      let result = await this.$store.dispatch('mayoristaCobranzas/formasPago', payload.item.numero)
      this.$store.state.loading = false

      if (result.exito == 1) {
        this.formas_pago = result.data
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    async getRemito (item) {
      const tipo = 'Remito'
      this.$store.state.loading = true
      let result_re = await this.$store.dispatch('ventasMay/get_datos_pdf', {
        venta_id: item.numero,
        comprobante: tipo
      })
      this.$store.state.loading = false

      if (result_re.exito == 1) {
        this.$store.state.loading = true
        this.pdf = await remitoReciboA4(result_re.data, tipo)
        this.$store.state.loading = false
        this.pdf_nombre = `${tipo} N° ${result_re.data.numero}`
        this.pdf_dialog = true
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result_re.message,
          color: 'error'
        })
      }
    },
    async getRecibo (item) {
      this.$store.state.loading = true
      const res = await this.$store.dispatch('mayoristaCobranzas/generarPdfCobranza', item.numero)
      this.$store.state.loading = false

      if (res.resultado == 1) {
        this.$store.state.loading = true
        this.pdf = await reciboLitechA4({
          cliente: res.cliente[0],
          encabezado: res.encabezado[0],
          saldos_usados: res.saldos_usados
        })
        this.$store.state.loading = false
        this.pdf_nombre = `Recibo N° ${item.numero}`
        this.pdf_dialog = true
      } else {
        this.$store.dispatch('show_snackbar', {
          text: res.message,
          color: 'error'
        })
      }
    },
    async buscar () {
      if (!this.gestor) {
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar un cliente',
          color: 'orange'
        })
        return
      }

      this.limpiar()

      this.load = true
      let result = await this.$store.dispatch('gestores/cta_cte', this.gestor)
      this.load = false

      if (result.exito != 1) {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
        return
      }

      this.data = result.cuenta
      this.favor = result.gestor.favor
      this.limite = result.gestor.limite
      this.calcularSaldos()

      if (this.numero) {
        let existe = this.data.find(dt => dt.numero == this.numero && dt.tipo == this.tipo_cob)
        if (existe) {
          const index = this.data.indexOf(existe)
          this.page = parseInt((this.data.length - index) / 30) + 1
          this.expanded.push(existe)
          await new Promise(resolve => setTimeout(resolve, 1))
          window.scroll({
            top: ((this.data.length - index) - ((this.page - 1) * 30)) * 30,
            behavior: "smooth"
          })
          this.getFormasPago({
            value: true,
            item: existe
          })
        }
      }

    },
    async getCliente () {
      if (!this.gestor) return

      this.$store.state.loading = true
      let result = await this.$store.dispatch('clientes/getClienteMayorista', {
        codigo: this.gestor,
        ejecutivo_codigo: this.dialog_clientes.ejecutivo_codigo,
        nroDocumento: 0,
        nombre: ''
      })
      this.$store.state.loading = false

      if (result.resultado == 1) {
        if (result.clientes.length == 1) {
          this.nombre = result.clientes[0].vendedor_nombre
          this.buscar()
          return
        } else {
          this.$store.dispatch('show_snackbar', {
            text: 'No se encontró el cliente ' + this.gestor,
            color: 'info'
          })
        }
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.msj,
          color: 'error'
        })
      }

      this.gestor = null
      this.nombre = null
    },
    setCliente (item) {
      this.gestor = item.vendedor_codigo
      this.nombre = item.vendedor_nombre
      this.buscar()
    },
    setDialogClienteMay (value) {
      this.dialog_clientes.activo = value
    },
    calcularSaldos () {
      let saldo = 0
      for (let item of this.data) {
        saldo += -1 * item.importe
        item.saldo = saldo
      }
      this.saldo = saldo
      this.disp_vta = this.saldo + this.limite + this.favor
    },
    verDetalle (item) {
      let ruta = ''
      switch (item.tipo) {
        case this.tipo_cob:
          ruta = '/cobranza/'
          break
        case this.tipo_vta:
          ruta = '/vta-mayorista/'
          break
        default:
          return
      }
      const routeData = this.$router.resolve({ path: ruta + item.numero })
      window.open(routeData.href, '_blank')
    },
    limpiar (con_gestor) {
      this.expanded = []
      this.data = []
      this.saldo = 0
      this.favor = 0
      this.limite = 0
      this.disp_vta = 0
      if (con_gestor == 1) {
        this.gestor = null
        this.nombre = null
      }
    },
    itemRowBackground (item) {
      return item.numero == this.$route.query.numero && item.tipo == this.tipo_cob ? 'background-color: rowsel' : '';
    }
  }
}
</script>