<template>
  <v-dialog
    v-model="dialog"
    max-width="350"
  >
    <v-card>
      <v-card-title>
        {{ credenciales.user && credenciales.pass ? 'Editar' : 'Crear' }} usuario
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="body-1 pt-4">
        <p class="text-left">
          Ingrese los datos para el usuario para del vendedor
          <strong>
            {{ nombres ? nombres : `${ apellido ? apellido.toUpperCase() : '' } ${ nombre ? nombre.toUpperCase() : '' }` }}
          </strong>:
        </p>
        <v-form ref="form" class="mx-2">
          <v-text-field
            v-model.trim="user"
            label="Usuario"
            tabindex="1"
            :rules="[rules.required]"
            validate-on-blur
            autofocus
          ></v-text-field>
          <v-text-field
            v-model.trim="pass"
            label="Clave"
            hint="Mínimo 6 caracteres"
            tabindex="1"
            :rules="[rules.min]"
            :append-icon="show_pass ? 'fas fa-eye' : 'fas fa-eye-slash'"
            :type="show_pass ? 'text' : 'password'"
            validate-on-blur
            counter
            @click:append="show_pass = !show_pass"
          ></v-text-field>
          <v-text-field
            v-model.trim="pass_repeat"
            label="Repita la clave"
            tabindex="1"
            :rules="[rules.pass_confirm]"
            :append-icon="show_pass_repeat ? 'fas fa-eye' : 'fas fa-eye-slash'"
            :type="show_pass_repeat ? 'text' : 'password'"
            validate-on-blur
            counter
            @click:append="show_pass_repeat = !show_pass_repeat"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          @click="confirmar()"
        >
          <v-icon left>fas fa-check</v-icon>
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Modal para "crear" un usuario, solo emite las credenciales que se ingresaron para q dsp el padre lo cree
 */
export default {
  data () {
    return {
      show_pass: false,
      show_pass_repeat: false,
      user: '',
      pass: '',
      pass_repeat: '',
      rules: {
        required: value => !!value || 'Campo requerido',
        min: value => value.length >= 6 || 'Mínimo 6 caracteres',
        pass_confirm: value => value === this.pass || 'Las claves no coinciden',
      }
    }
  },
  props: {
    value: Boolean,
    credenciales: Object, // debe contener user y pass
    nombres: String,
    apellido: String,
    nombre: String
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    dialog (val) {
      this.show_pass = false
      this.show_pass_repeat = false

      if (val) {
        if (this.credenciales.user && this.credenciales.pass) {
          this.user = JSON.parse(JSON.stringify(this.credenciales.user))
          this.pass = JSON.parse(JSON.stringify(this.credenciales.pass))
          this.pass_repeat = JSON.parse(JSON.stringify(this.credenciales.pass))
        }
      } else {
        this.$refs.form.resetValidation()
        this.user = ''
        this.pass = ''
        this.pass_repeat = ''
      }
    }
  },
  methods: {
    async confirmar () {
      if (!this.$refs.form.validate()) return

      this.$store.state.loading = true
      let result = await this.$store.dispatch('validar_usuario', this.user)
      this.$store.state.loading = false

      if (result.exito != 1) {
        this.$swal.fire({
          icon: 'warning',
          title: result.message
        })
        return
      }

      this.$emit('updateCredentials', {
        user: this.user,
        pass: this.pass
      })
      this.dialog = false
    }
  }
}
</script>